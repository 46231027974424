import React from "react"
import { useLocalization } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { UserConnect } from "@bw/partials"
import { Layout } from "@bw/layouts"
import { Section, Button, Avatar, Loader } from "@bw/bits"
import { getMe, editProfile } from "@actions/user"
import { useUser } from "@contexts/user"
import { breakpoints } from "../../theme"
import { toast } from "react-toastify"
import { FormField, Input, FileInput, TextArea } from "@bw/forms"
import { navigate } from "gatsby"
import { Formik, Form, Field } from "formik"
import * as yup from "yup"

const userSchema = yup.object({
  name: yup
    .string()
    .required()
    .matches(
      new RegExp("^[a-zA-Z0-9_]+$"),
      "Only letters, numbers and underscores are allowed."
    )
    .min(3)
    .max(128),
  email: yup.string().required().email(),
  bio: yup.string().max(1024),
  avatar: yup.mixed().nullable(),
  website: yup.string().url(),
})

function Edit({ pageContext }) {
  const { localizedPath, locale, defaultLang } = useLocalization()
  const [user, dispatch] = useUser()
  const { t } = useTranslation()
  const transparentPixel =
    "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
  const [preview, setPreview] = React.useState(null)

  const meQuery = useQuery(
    `edit_me_${user.publicKey}_${user.loggedIn ? "login" : "logout"}`,
    () => {
      return user.loggedIn ? getMe(dispatch) : null
    }
  )

  if (typeof window === "undefined") {
    // FileReader cant' be server side rendered
    return null
  }

  if (!user.loggedIn) {
    navigate(
      localizedPath({
        defaultLang,
        locale,
        path: `/user/login/`,
      })
    )
    return null
  }

  const reader = new FileReader()

  return (
    <Layout {...{ pageContext }}>
      <Section>
        <Button
          to={`/user/${user.publicKey}`}
          label={t("return")}
          small
          icon="ArrowLeft"
          direction="row-reverse"
        />
      </Section>
      <Section title="Profile edition">
        {meQuery.isLoading && (
          <Loader
            messages={[
              t("loading.yourProfile"),
              t("loading.itTakesLongerThanExpected"),
            ]}
            treshold={20}
          />
        )}
        {!meQuery.isLoading && (
          <Formik
            validationSchema={userSchema}
            initialValues={{
              name: user.data.name || "",
              email: user.data.email || "",
              bio: user.data.bio || "",
              website: user.data.website || "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              editProfile(values, dispatch)
                .then(() => {
                  toast.success(t("user.toastProfileUpdateSuccess"))

                  navigate(
                    localizedPath({
                      defaultLang,
                      locale,
                      path: `/user/${user.publicKey}/`,
                    })
                  )
                })
                .catch(err => {
                  console.log(err.details)
                  toast.error(t("user.toastProfileUpdateFail"))

                  // these are server validation errors
                  err?.response?.data?.errors &&
                    Object.values(err.response.data.errors).forEach(error => {
                      toast.error(error.join("\n"))
                    })
                })
                .finally(() => {
                  setSubmitting(false)
                })
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <GridContainer>
                  <div style={{ maxWidth: "316px" }}>
                    <FormField name="avatar">
                      <Flex>
                        <Avatar
                          size="large"
                          image={
                            preview || user.data.avatar_url || transparentPixel
                          }
                          alt="profile picture."
                        />
                        <div>
                          <FileInput
                            name="avatar"
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onClear={() => {
                              setFieldValue("avatar", null)
                              setPreview(null)
                            }}
                            onChange={e => {
                              setFieldValue("avatar", e.currentTarget.files[0])
                              reader.onload = () => {
                                setPreview(reader.result)
                              }
                              reader.readAsDataURL(e.currentTarget.files[0])
                            }}
                          />
                        </div>
                      </Flex>
                    </FormField>
                    <Nickname>
                      @{user.data.name}
                      <Identity>
                        {user.publicKey.slice(0, 4)}...
                        {user.publicKey.slice(-4)}
                      </Identity>
                    </Nickname>
                    <Email>{user.data.email}</Email>
                    <Biography>{user.data.bio}</Biography>
                  </div>
                  <div style={{ maxWidth: "316px" }}>
                    <FormField
                      title={t("user.form.name")}
                      name="name"
                      icon="Public"
                    >
                      <Field
                        name="name"
                        type="text"
                        required
                        component={Input}
                      />
                    </FormField>
                    <FormField
                      title={t("user.form.email")}
                      name="email"
                      icon="Private"
                    >
                      <Field
                        name="email"
                        type="email"
                        required
                        component={Input}
                      />
                    </FormField>
                    <FormField
                      title={t("user.form.bio")}
                      name="bio"
                      icon="Public"
                    >
                      <Field name="bio" component={TextArea} />
                    </FormField>
                    <FormField
                      title={t("user.form.website")}
                      name="website"
                      icon="Public"
                    >
                      <Field name="website" component={Input} />
                    </FormField>
                    {user.publicKey ? (
                      <Button
                        submit
                        disabled={isSubmitting}
                        label={t("user.form.buttonUpdateProfile")}
                      />
                    ) : (
                      <UserConnect />
                    )}
                  </div>
                </GridContainer>
              </Form>
            )}
          </Formik>
        )}
      </Section>
    </Layout>
  )
}

const GridContainer = styled.div`
  padding: 10px;
  width: 100%;
  justify-content: center;

  @media (min-width: ${breakpoints.medium}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
`

const Flex = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
`
const Nickname = styled.h3`
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  letter-spacing: 0.025em;
  color: #04062c;
  margin: 50px 0px;
  font-weight: 800;
`
const Identity = styled.span`
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
  font-weight: 500;
`

const Email = styled.p`
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
  font-weight: 800;
`

const Biography = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #04062c;
  opacity: 0.7;
  margin-bottom: 40px;
`

export default Edit
